<template>
  <div class="chart-set"></div>
</template>

<script>
  export default {
    props: {
      title: String,
      transparent: {
        type: Boolean,
        default: false
      },
      fill: {
        type: Boolean,
        default: false
      },
      data: {
        type: Array,
        required: true
      },
      color: {
        type: String,
        default: 'primary'
      }
    },
    data() {
      return {
        id: undefined,
        colors: {
          light: '#c7d3db',
          muted: '#6C757D',
          dark: '#3E4A59',
          primary: '#5995ed',
          info: '#54a9ab',
          warning: '#fec369',
          danger: '#F24F54',
          success: '#80ba7e'
        },
        chartEl: this.$parent.$refs['chart']
      }
    },
    watch: {

      data(newVal) {

        this.$parent.chart.data.datasets[this.id].data = Object.values(newVal);
        this.$parent.chart.update();
      },

      color() {

        this.$parent.chart.data.datasets[this.id].borderColor = this._color;
        this.$parent.chart.data.datasets[this.id].pointBackgroundColor = this._color;
        this.$parent.chart.data.datasets[this.id].backgroundColor = this.gradient;

        this.$parent.chart.update();
      }
    },
    computed: {

      _color() {

        return this.colors[this.color];
      },

      gradient() {

        let gradientFill = this.chartEl.getContext("2d").createLinearGradient(0, 0, 0, this.chartEl.parentNode['offsetHeight']);
        gradientFill.addColorStop(0, this.rgba(this.colors[this.color], .5));
        gradientFill.addColorStop(1, this.rgba(this.colors[this.color], 0));

        return gradientFill;
      }
    },
    mounted() {
      this.id = this.$parent.sets.length;

      let props = {
        fill: true,
        label: this.title,
        data: this.data,
        borderColor: this._color,
        backgroundColor: this.gradient,
        pointRadius: 3,
        pointHoverRadius: 5,
        pointBackgroundColor: this._color,
        pointBorderColor: '#fff',
        borderWidth: 1
      }

      if(this.transparent) props.backgroundColor = 'transparent';
      if(this.fill) props.backgroundColor = this.rgba(this._color, .3);

      this.$parent.sets.push(props)
    },
    methods: {
      rgba(hex, opacity = 1) {

        let c;

        if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {

          c= hex.substring(1).split('');

          if(c.length === 3)
            c= [c[0], c[0], c[1], c[1], c[2], c[2]];

          c= '0x'+c.join('');

          return 'rgba('+[(c>>16)&255, (c>>8)&255, c&255].join(', ')+', '+opacity+')';
        }
        throw new Error('Bad Hex');
      }
    }
  }
</script>
